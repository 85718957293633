import axios from 'axios'
import { EnvEnum, getEnv } from './env'

const env = getEnv()

const isMiniProgramProdEnv =
  typeof __wxConfig != 'undefined' && __wxConfig.envVersion === 'release'

const baseURL =
  env === EnvEnum.wxMiniProgram
    ? isMiniProgramProdEnv
      ? 'https://bondowner.cn'
      : 'https://t.bondowner.cn'
    : window.location.origin

const http = axios.create({
  baseURL,
  timeout: 1e4
})
const appSuffix = 'app=applet'

http.interceptors.request.use(config => {
  if (config.url) {
    if (!(config.params && config.params.app) && !/app=/.test(config.url)) {
      config.url = /\?/.test(config.url)
        ? `${config.url}&${appSuffix}`
        : `${config.url}?${appSuffix}`
    }
  }

  return config
})

http.interceptors.response.use(
  response => {
    const data = response.data

    if (data.status === undefined) {
      return response.data
    }

    if (data.status === 'ok' || data.status) {
      if (data.data) {
        return Promise.resolve(data.data)
      } else {
        return Promise.resolve(data)
      }
    } else {
      return Promise.reject(data.message || data.status)
    }
  },
  error => {
    console.error(error.message)
    return Promise.reject(error)
  }
)

const API = {
  loginInMiniProgram(params) {
    return http.get('/lite/login', { params })
  },
  registerInMiniProgram(appId, params) {
    return http.post(`/lite/register?app_id=${appId}`, params)
  },
  loginInMiniProgramForPFB: key => {
    return http.get('/pfb/api/v1/miniprogram-login', { params: { key } })
  },
  authInOfficialAccount: (appid, code) => {
    return http.get('/pfb/api/v1/login-by-code', {
      params: { appid, code, state: 'pfb/auth' }
    })
  },
  getUserInfo: () => {
    return http.get('/pfb/api/v1/user/info')
  },
  getTasks: () => {
    return http.get('/pfb/api/v1/applet/todo')
  },
  getTaskImage: (checksum, page) => {
    return `${baseURL}/pfb/api/v1/files/${checksum}/page/${page}/applet-todo-image`
  },
  getTaskImageByWx: (checksum, page) => {
    if (env === EnvEnum.wxMiniProgram) {
      return new Promise(resolve =>
        wx.downloadFile({
          url: `${baseURL}/pfb/api/v1/files/${checksum}/page/${page}/applet-todo-image`,
          success(res) {
            resolve(res.tempFilePath)
          },
          fail(res) {
            console.error(res)
          }
        })
      )
    } else {
      return Promise.resolve(
        `${baseURL}/pfb/api/v1/files/${checksum}/page/${page}/applet-todo-image`
      )
    }
  },
  updateTaskAnswer: (checksum, page, data) => {
    return http.post(
      `/pfb/api/v1/files/${checksum}/page/${page}/applet-answer`,
      data
    )
  }
}

export default API
