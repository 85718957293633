import React from 'react'
import './index.less'
import { miniProgramAuthorize } from '../../utils/wechat-auth'
import { loading } from '../../utils/loading'

const WxMiniProgramAuth = props => {
  const { ready, handleAuthComplete } = props

  const checkAuthStatus = async e => {
    try {
      const userInfoRes = await new Promise((resolve, reject) => {
        wx.getUserInfo({
          success(res) {
            resolve(res)
          },
          fail() {
            return reject('wx.getUserInfo failed')
          }
        })
      })

      if (userInfoRes.userInfo) {
        loading.show()

        try {
          await miniProgramAuthorize.processAuth()
          await handleAuthComplete()

          loading.hide()
        } catch (error) {
          loading.hide()
        }
      }
    } catch (error) {
      console.error(error)
      setTimeout(() => checkAuthStatus(), 1000)
    }
  }

  return (
    <div className="wx-miniprogram-auth-component">
      <div className="header">
        <div className="icon-logo"></div>
        <div className="title">
          <h1>PDFlux</h1>
          <h2>元素块找茬</h2>
        </div>
      </div>
      {ready ? (
        <div className="operation">
          <wx-button
            open-type="getUserInfo"
            onClick={checkAuthStatus}
            class="wx-auth-operation"
          >
            微信授权登录
          </wx-button>
        </div>
      ) : null}
    </div>
  )
}

export default WxMiniProgramAuth
