import React, { useState, useEffect } from 'react'
import Viewer from './components/viewer'
import Remark from './components/remark'
import WxMiniProgramAuth from './components/wx-mp-auth'
import { EnvEnum, getEnv } from './utils/env'
import { getQuery } from './utils/location'
import {
  miniProgramAuthorize,
  officialAccountAuthorize
} from './utils/wechat-auth'
import API from './utils/api'
import { loading } from './utils/loading'

const env = getEnv()

const App = () => {
  const [ready, setReady] = useState(false)
  const [hasAuth, setHasAuth] = useState(false)
  const [userInfo, setUserInfo] = useState({ avatar: '', points: 0, counts: 0 })
  const [taskList, setTaskList] = useState([])
  const [taskCurrent, setTaskCurrent] = useState({
    fileIndex: -1,
    elementIndex: -1
  })
  const [miniProgramInfo, setMiniPrograminfo] = useState({ screenWidth: 0 })

  const fetchUserInfo = async () => {
    const userInfoRes = await API.getUserInfo()
    setUserInfo({
      avatar: userInfoRes.avatar,
      counts: Number(userInfoRes.rest_counts),
      points: Number(userInfoRes.rest_points)
    })
  }

  const fetchTasks = async () => {
    const tasksRes = await API.getTasks()

    if (Array.isArray(tasksRes)) {
      let taskList = await Promise.all(
        tasksRes.map(async item => {
          let elementList = []
          const elementType = [
            'tables',
            'paragraphs',
            'page_headers',
            'page_footers',
            'footnotes',
            'shapes',
            'images'
          ]

          const elementTypeName = {
            PAGE_HEADER: '页眉',
            PAGE_FOOTER: '页脚',
            PARAGRAPH_1: '单行段落',
            PARAGRAPH_2: '多行段落',
            IMAGE: '图片',
            SHAPE: '附注',
            FOOTNOTE: '附注'
          }

          elementType.forEach(type => {
            if (item.prediction[type]) {
              elementList = elementList.concat(
                item.prediction[type].map(item => {
                  let elementName = ''
                  if (type === 'tables') {
                    elementName = '表格'
                  } else if (elementTypeName[item.type]) {
                    elementName = elementTypeName[item.type]
                  }

                  return {
                    name: elementName,
                    outline: item.outline
                  }
                })
              )
            }
          })

          elementList.sort((a, b) => a.outline[1] - b.outline[1])

          const imageUrl = await API.getTaskImageByWx(item.checksum, item.page)

          return {
            checksum: item.checksum,
            page: item.page,
            imageUrl,
            elementList
          }
        })
      )

      taskList = taskList.filter(item => item.elementList.length > 0)

      setTaskList(taskList)
      if (taskList.length > 0) {
        setTaskCurrent({ fileIndex: 0, elementIndex: -1 })
      }
    }
  }

  const fetchMiniProgramInfo = async () => {
    await new Promise(resolve => {
      wx.getSystemInfo({
        success(info) {
          setMiniPrograminfo(info)
          resolve()
        }
      })
    })
  }

  const gotoNextTask = async () => {
    if (taskCurrent.fileIndex < taskList.length - 1) {
      if (
        taskCurrent.elementIndex <
        taskList[taskCurrent.fileIndex].elementList.length - 1
      ) {
        setTaskCurrent({
          ...taskCurrent,
          elementIndex: taskCurrent.elementIndex + 1
        })
      } else {
        setTaskCurrent({
          fileIndex: taskCurrent.fileIndex + 1,
          elementIndex: 0
        })
      }
    } else {
      setTaskCurrent({ fileIndex: -1, elementIndex: -1 })
      await fetchTasks()

      if (taskList.length > 0 && taskList[0].elementList.length > 0) {
        setTaskCurrent({ fileIndex: 0, elementIndex: 0 })
      }
    }
  }

  const initialize = async () => {
    loading.show()

    if (env === EnvEnum.wxMiniProgram) {
      try {
        await miniProgramAuthorize.checkAuth()
        await miniProgramAuthorize.processAuth()
        await fetchUserInfo()
        await fetchTasks()
        await fetchMiniProgramInfo()
        setHasAuth(true)
      } catch (error) {
        console.error(error)
        setHasAuth(false)
      }
    } else if (env === EnvEnum.wxOfficialAccount) {
      const { code } = getQuery()

      if (code) {
        await officialAccountAuthorize.processAuth(code)
        await fetchUserInfo()
        await fetchTasks()
        setHasAuth(true)
      } else {
        officialAccountAuthorize.getCode()
      }
    } else {
      await fetchUserInfo()
      await fetchTasks()
      setHasAuth(true)
    }

    setReady(true)

    loading.hide()
  }

  const wxMiniProgramAuthHandler = async () => {
    await fetchUserInfo()
    await fetchTasks()
    setHasAuth(true)
  }

  useEffect(() => {
    initialize()
  }, [])

  return hasAuth ? (
    <div>
      <Viewer
        taskList={taskList}
        taskCurrent={taskCurrent}
        miniProgramInfo={miniProgramInfo}
      />
      <Remark
        userInfo={userInfo}
        taskList={taskList}
        taskCurrent={taskCurrent}
        gotoNextTask={gotoNextTask}
      />
    </div>
  ) : (
    <WxMiniProgramAuth
      ready={ready}
      handleAuthComplete={wxMiniProgramAuthHandler}
    />
  )
}

export default App
