export const EnvEnum = {
  wxMiniProgram: 0,
  wxOfficialAccount: 1,
  web: 2
}
export const getEnv = () => {
  const ua = window.navigator.userAgent

  if (typeof wx != 'undefined' && wx.getSystemInfoSync) {
    return EnvEnum.wxMiniProgram
  } else if (/MicroMessenger/i.test(ua)) {
    return EnvEnum.wxOfficialAccount
  } else {
    return EnvEnum.web
  }
}
