import { EnvEnum, getEnv } from './env'

const env = getEnv()

export const loading =
  env === EnvEnum.wxMiniProgram
    ? {
        show: wx.showLoading,
        hide: wx.hideLoading
      }
    : {
        show: () => {},
        hide: () => {}
      }
