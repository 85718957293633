export const getQuery = () => {
  const search = window.location.search.slice(1)

  return decodeURIComponent(search)
    .split('&')
    .reduce((queryMap, queryStr) => {
      const queryArr = queryStr.split('=')

      if (queryArr.length > 0) {
        queryMap[queryArr[0]] = decodeURIComponent(queryArr[1])
      }

      return queryMap
    }, {})
}
