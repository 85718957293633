import API from './api'

const miniProgramAppId = 'wx66e2924e0f52008b'
const officialAccountAppId = 'wxaef60410f098bd24'

export const miniProgramAuthorize = {
  checkAuth: async () => {
    await new Promise((resolve, reject) => {
      wx.getSetting({
        success: res => {
          console.log('wx.getSetting', res)
          const hasAuth = res.authSetting['scope.userInfo']
          if (hasAuth) {
            resolve()
          } else {
            reject('unauth')
          }
        }
      })
    })
  },
  processAuth: async () => {
    const code = await new Promise((resolve, reject) => {
      wx.login({
        success(res) {
          resolve(res.code)
        },
        fail() {
          return reject('wx.login failed')
        }
      })
    })

    const { key, user } = await API.loginInMiniProgram({
      code,
      app_id: miniProgramAppId
    })

    console.log('user', user)

    if (!user) {
      const userInfoRes = await new Promise((resolve, reject) => {
        wx.getUserInfo({
          success(res) {
            console.log('wx.getUserInfo', res)
            resolve(res)
          },
          fail() {
            return reject('wx.getUserInfo failed')
          }
        })
      })

      const registerParams = {
        key,
        encrypted_data: userInfoRes.encryptedData,
        iv: userInfoRes.iv
      }
      await API.registerInMiniProgram(miniProgramAppId, registerParams)
    }

    await API.loginInMiniProgramForPFB(key)
  }
}

export const officialAccountAuthorize = {
  getCode: () => {
    const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${officialAccountAppId}&redirect_uri=${encodeURIComponent(
      window.location.href
    )}&response_type=code&scope=snsapi_userinfo&state=${encodeURIComponent(
      'pfb/auth'
    )}#wechat_redirect`

    window.location.href = authURL
  },
  processAuth: async code => {
    await API.authInOfficialAccount(officialAccountAppId, code)
  }
}
