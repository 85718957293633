import React, { useState } from 'react'
import './index.less'
import API from '../../utils/api'
import { EnvEnum, getEnv } from '../../utils/env'

const env = getEnv()

const remarkStatusEnum = {
  initial: 0,
  tutorial: 1,
  operate: 2,
  complete: 3
}

const delay = async milliseconds => {
  await new Promise(resolve => setTimeout(resolve, milliseconds))
}

const Remark = props => {
  const { userInfo, taskList, taskCurrent, gotoNextTask } = props

  const [remarkStatusCurrent, setRemarkStatusCurrent] = useState(
    remarkStatusEnum.initial
  )
  const [remarkWrongConfirm, setRemarkWrongConfirm] = useState(false)
  const [remarkOperateAnimation, setRemarkOperateAnimation] = useState({
    left: false,
    right: false
  })

  const startRemark = () => {
    gotoNextTask()
    setRemarkStatusCurrent(remarkStatusEnum.operate)
  }

  const remarkComplete = () => {
    setRemarkStatusCurrent(remarkStatusEnum.complete)
    setTimeout(() => startRemark(), 600)
  }

  const remarkOperateWithAnimation = async (panel, operateFn) => {
    setRemarkOperateAnimation({ ...remarkOperateAnimation, [panel]: true })
    await delay(180)
    setRemarkOperateAnimation({ ...remarkOperateAnimation, [panel]: false })
    operateFn()
  }

  const confirmRemarkWrong = async () => {
    remarkOperateWithAnimation('left', () => setRemarkWrongConfirm(true))
  }
  const remarkWrong = async () => {
    try {
      const params = {
        correct: false,
        first_element:
          taskList[taskCurrent.fileIndex].elementList[taskCurrent.elementIndex]
            .outline
      }
      await API.updateTaskAnswer(
        taskList[taskCurrent.fileIndex].checksum,
        taskList[taskCurrent.fileIndex].page,
        params
      )

      remarkComplete()
      setRemarkWrongConfirm(false)
    } catch (error) {
      console.error(error)
    }
  }

  const remarkCorrect = () => {
    try {
      remarkOperateWithAnimation('right', async () => {
        const params = { correct: true }
        await API.updateTaskAnswer(
          taskList[taskCurrent.fileIndex].checksum,
          taskList[taskCurrent.fileIndex].page,
          params
        )

        remarkComplete()
      })
    } catch (error) {
      console.error(error)
    }
  }

  return env === EnvEnum.wxMiniProgram ? (
    <cover-view className="remark-component">
      {remarkStatusCurrent === remarkStatusEnum.initial ? (
        <cover-view className="initial-status">
          <cover-view className="initial-header">
            <img className="user-avatar" src={userInfo.avatar}></img>
            <cover-view className="user-balance">
              <cover-view class="user-banlance-point">
                {userInfo.points}
              </cover-view>
              <i className="icon icon-point"></i>
            </cover-view>
          </cover-view>
          <cover-view className="initial-operation">
            <button
              onClick={() => setRemarkStatusCurrent(remarkStatusEnum.tutorial)}
            >
              开始找茬
            </button>
          </cover-view>
        </cover-view>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.tutorial ? (
        <cover-view className="tutorial-status">
          <cover-view className="tutorial-explain">
            <cover-view className="tutorial-explain-content">
              查看文件时，
            </cover-view>
            <cover-view className="tutorial-explain-content">
              点击屏幕左侧或右侧可进行相应操作
            </cover-view>
          </cover-view>
          <cover-view className="tutorail-demonstration">
            <cover-view className="left-panel">
              <cover-view class="tutorail-demonstration-content">
                标记为出错
              </cover-view>
            </cover-view>
            <cover-view className="right-panel">
              <cover-view class="tutorail-demonstration-content">
                没错，
              </cover-view>
              <cover-view class="tutorail-demonstration-content">
                看下一个
              </cover-view>
            </cover-view>
          </cover-view>
          <cover-view className="tutorial-operation">
            <button onClick={() => startRemark()}>知道了</button>
          </cover-view>
        </cover-view>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.operate ? (
        <cover-view className="operate-status">
          {remarkWrongConfirm ? (
            <cover-view className="operate-status-panel">
              <cover-view
                className="left-panel remark-wrong-confirm"
                onClick={remarkWrong}
              >
                <cover-view className="operation-prompt-content">
                  确认
                </cover-view>
                <cover-view className="operation-prompt-content">
                  标记为出错吗？
                </cover-view>
                <i className="icon-confirm"></i>
              </cover-view>
              <cover-view
                className="right-panel"
                onClick={() => setRemarkWrongConfirm(false)}
              ></cover-view>
            </cover-view>
          ) : (
            <cover-view className="operate-status-panel">
              <cover-view className="left-panel" onClick={confirmRemarkWrong}>
                {remarkOperateAnimation.left ? (
                  <cover-view class="left-panel-anination"></cover-view>
                ) : null}
              </cover-view>
              <cover-view className="right-panel" onClick={remarkCorrect}>
                {remarkOperateAnimation.right ? (
                  <cover-view class="right-panel-anination"></cover-view>
                ) : null}
              </cover-view>
            </cover-view>
          )}
        </cover-view>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.complete ? (
        <cover-view className="complete-status">
          <cover-view className="complete-status-thanks">
            <cover-view className="complete-status-content">
              感谢你的帮助！
            </cover-view>
            <cover-view className="complete-status-content">
              奖励已发放到账户
            </cover-view>
          </cover-view>
          <cover-view>
            <cover-view className="complete-status-content">
              即将展示下一题...
            </cover-view>
          </cover-view>
        </cover-view>
      ) : null}
    </cover-view>
  ) : (
    <div className="remark-component">
      {remarkStatusCurrent === remarkStatusEnum.initial ? (
        <div className="initial-status">
          <div className="initial-header">
            <img className="user-avatar" src={userInfo.avatar} alt="avatar" />
            <div className="user-balance">
              <span>{userInfo.points}</span>
              <i className="icon icon-point"></i>
            </div>
          </div>
          <div className="initial-operation">
            <button
              onClick={() => setRemarkStatusCurrent(remarkStatusEnum.tutorial)}
            >
              开始找茬
            </button>
          </div>
        </div>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.tutorial ? (
        <div className="tutorial-status">
          <div className="tutorial-explain">
            <p>查看文件时，</p>
            <p>点击屏幕左侧或右侧可进行相应操作</p>
          </div>
          <div className="tutorail-demonstration">
            <div className="left-panel">
              <p>标记为出错</p>
            </div>
            <div className="right-panel">
              <p>没错，</p>
              <p>看下一个</p>
            </div>
          </div>
          <div className="tutorial-operation">
            <button onClick={() => startRemark()}>知道了</button>
          </div>
        </div>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.operate ? (
        <div className="operate-status">
          {remarkWrongConfirm ? (
            <div className="operate-status-panel">
              <div className="remark-wrong-confirm" onClick={remarkWrong}>
                <p>确认</p>
                <p>标记为出错吗？</p>
                <i className="icon-confirm"></i>
              </div>
              <div
                className="right-panel"
                onClick={() => setRemarkWrongConfirm(false)}
              ></div>
            </div>
          ) : (
            <div className="operate-status-panel">
              <div
                className={
                  remarkOperateAnimation.left
                    ? 'left-panel active'
                    : 'left-panel'
                }
                onClick={confirmRemarkWrong}
              ></div>
              <div
                className={
                  remarkOperateAnimation.right
                    ? 'right-panel active'
                    : 'right-panel'
                }
                onClick={remarkCorrect}
              ></div>
            </div>
          )}
        </div>
      ) : null}
      {remarkStatusCurrent === remarkStatusEnum.complete ? (
        <div className="complete-status">
          <div>
            <p>感谢你的帮助！</p>
            <p>奖励已发放到账户</p>
          </div>
          <div>
            <p>即将展示下一题...</p>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Remark
