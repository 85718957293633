// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/wx-mp-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/pdflux-logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".wx-miniprogram-auth-component {\n  position: fixed;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  display: flex;\n  flex-flow: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n  background-color: #010101;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow: hidden;\n}\n.wx-miniprogram-auth-component .header {\n  position: relative;\n  z-index: 2;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  margin-top: 120px;\n}\n.wx-miniprogram-auth-component .icon-logo {\n  display: block;\n  width: 120px;\n  height: 120px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: contain;\n}\n.wx-miniprogram-auth-component .title {\n  margin-top: 30px;\n}\n.wx-miniprogram-auth-component .title h1 {\n  margin: 0;\n  font-size: 26px;\n  font-weight: 500;\n  color: #ffffff;\n}\n.wx-miniprogram-auth-component .title h2 {\n  margin: 0;\n  margin-top: 5px;\n  font-size: 18px;\n  font-weight: 500;\n  color: #ffffff;\n}\n.wx-miniprogram-auth-component .operation {\n  position: relative;\n  z-index: 2;\n  margin-bottom: 20%;\n}\n.wx-miniprogram-auth-component .operation button,\n.wx-miniprogram-auth-component .operation .wx-auth-operation {\n  display: block;\n  margin: 0 30px;\n  width: calc(100% - 60px);\n  height: 49px;\n  line-height: 48px;\n  background: transparent;\n  border: 1px solid #fff;\n  border-radius: 5.7px;\n  font-size: 18px;\n  font-weight: 500;\n  color: #fff;\n  text-align: center;\n}\n", ""]);
// Exports
module.exports = exports;
