import React, { useRef } from 'react'
import './index.less'
import API from '../../utils/api'
import { EnvEnum, getEnv } from '../../utils/env'
const env = getEnv()

const Viewer = props => {
  const { taskList, taskCurrent, miniProgramInfo } = props

  const imageZoomInEl = useRef(null)
  const canvasOriginalEl = useRef(null)
  const imageZoomContainer = useRef(null)

  let imageUrl = ''
  let outlineCroped = [0, 0, 0, 0]
  let sourceX = 0
  let sourceY = 0
  let widthToCrop = 0
  let heightToCrop = 0
  let canvasStyle = {
    width: 0,
    height: 0
  }
  let elementName = ''

  if (taskCurrent.fileIndex > -1) {
    imageUrl = taskList[taskCurrent.fileIndex].imageUrl

    if (taskCurrent.elementIndex > -1) {
      elementName =
        taskList[taskCurrent.fileIndex].elementList[taskCurrent.elementIndex]
          .name

      if (env === EnvEnum.wxMiniProgram) {
        outlineCroped = taskList[taskCurrent.fileIndex].elementList[
          taskCurrent.elementIndex
        ].outline.map(item => item * miniProgramInfo.pixelRatio)

        sourceX = outlineCroped[0]
        sourceY = outlineCroped[1]
        widthToCrop = outlineCroped[2] - outlineCroped[0]
        heightToCrop = outlineCroped[3] - outlineCroped[1]

        canvasStyle = {
          width: miniProgramInfo.windowWidth * 0.9,
          height: Math.max(
            150,
            Math.round(
              (miniProgramInfo.windowWidth * 0.9 * heightToCrop) / widthToCrop
            )
          )
        }

        // canvasStyle = {
        //   width: miniProgramInfo.windowWidth * 0.9,
        //   height: Math.max(
        //     150,
        //     Math.round(
        //       (miniProgramInfo.windowWidth * 0.9 * heightToCrop) / widthToCrop
        //     )
        //   )
        // }

        canvasStyle = {
          width: 300,
          height: 150
        }

        if (canvasOriginalEl.current) {
          canvasOriginalEl.current.$$getNodesRef().then(nodesRef => {
            nodesRef.node().exec(nodes => {
              const canvasNode = nodes[0].node
              const ctx = canvasNode.getContext('2d')
              ctx.clearRect(0, 0, 300, 150)

              const canvasImage = canvasNode.createImage()
              canvasImage.onload = () => {
                ctx.drawImage(
                  canvasImage,
                  sourceX,
                  sourceY,
                  widthToCrop,
                  heightToCrop,
                  0,
                  0,
                  canvasStyle.width,
                  canvasStyle.height
                )

                imageZoomContainer.current.classList.remove('hide')
              }

              canvasImage.src = imageUrl
            })
          })
        }
      } else {
        outlineCroped = taskList[taskCurrent.fileIndex].elementList[
          taskCurrent.elementIndex
        ].outline.map(item => item * window.devicePixelRatio)

        sourceX = outlineCroped[0]
        sourceY = outlineCroped[1]
        widthToCrop = outlineCroped[2] - outlineCroped[0]
        heightToCrop = outlineCroped[3] - outlineCroped[1]

        const imgEl = new Image()
        imgEl.onload = () => {
          const canvasEl = document.createElement('canvas')
          const ctx = canvasEl.getContext('2d')
          canvasEl.width = widthToCrop
          canvasEl.height = heightToCrop

          ctx.drawImage(
            imgEl,
            sourceX,
            sourceY,
            widthToCrop,
            heightToCrop,
            0,
            0,
            widthToCrop,
            heightToCrop
          )

          imageZoomInEl.current.src = canvasEl.toDataURL()
          imageZoomContainer.current.classList.remove('hide')
        }

        imgEl.src = imageUrl
      }
    }
  } else {
    imageUrl = ''
  }

  return (
    <div className="viewer-component">
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="viewer"
          className="viewer-image-original"
        ></img>
      ) : null}
      <div ref={imageZoomContainer} className="viewer-zoom hide">
        <div className="viewer-zoom-shadow"></div>
        {env === EnvEnum.wxMiniProgram ? (
          <div className="viewer-zoom-body-canvas">
            {elementName ? (
              <div className="current-element-name">{elementName}</div>
            ) : null}
            <canvas
              type="2d"
              ref={canvasOriginalEl}
              id="zoominCanvas"
              canvas-id="zoominCanvas"
              width={canvasStyle.width}
              height={canvasStyle.height}
            ></canvas>
          </div>
        ) : (
          <div className="viewer-zoom-body">
            {elementName ? (
              <div className="current-element-name">{elementName}</div>
            ) : null}
            <img
              ref={imageZoomInEl}
              src=""
              alt="viewerZoom"
              className="viewer-zoom-image"
            ></img>
          </div>
        )}
      </div>
    </div>
  )
}

export default Viewer
